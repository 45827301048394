import React from "react";

import { useIsAuthenticated } from "@azure/msal-react";


export const PageLayout = (props) => {
    // eslint-disable-next-line
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            {props.children}
        </>
    );
};