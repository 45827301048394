import React, { useState } from "react";
import Button from "react-bootstrap/Button";

export const RowData = (obj) => {
    const [showMore, setShowMore] = useState(false);

    function RowDetail() {
        setShowMore(!showMore)
        if (!showMore) {
            window.dataLayer.push({
                'event': 'detailButton',
                'firstName': obj.rowData.firstName,
                'lastName': obj.rowData.lastName
            });
        };
    }

    return (
        <>
            <td>{obj.rowData.firstName}</td><td>{obj.rowData.lastName}</td><td><Button id={"detailButton" + obj.rowData.id} variant="light" onClick={RowDetail}>{showMore ? <img src="less-arrow.png" alt=""></img> : <img src="more-arrow.png" alt=""></img>}</Button></td> 
        </>
    );
};