import './App.css';

import React, { useState } from "react";
import { PageLayout } from "./components/PageLayout";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { tokenRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { ContentData } from "./components/ContentData";
import { callGlauxiApi } from "./glauxiApi";
import {Nav, Navbar, Container} from "react-bootstrap";

function App() {
  return (
      <PageLayout>
            <AuthenticatedTemplate>
                <AppContent />
            </AuthenticatedTemplate>       
            <UnauthenticatedTemplate>
                <div className="logo">
                    <img src="glauxi.png" alt="Glauxi Logo"></img>
                </div>
                <h1><center>Glauxi</center></h1>
                <div className="text-center"><SignInButton /></div>
            </UnauthenticatedTemplate>
        </PageLayout>
    );
  }

function AppContent() {
    // eslint-disable-next-line
    const { instance, accounts, inProgress } = useMsal();
    const [contentData, setContentData] = useState(null);

    const name = accounts[0] && accounts[0].name;

    function RequestContentData() {

        const request = {
            ...tokenRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to api request
        instance.acquireTokenSilent(request).then((acquireResponse) => {
            callGlauxiApi(acquireResponse.accessToken).then(contentResponse => setContentData(contentResponse));
            
        }).catch((e) => {
            instance.acquireTokenRedirect(request).then((acquireResponse) => {
                callGlauxiApi(acquireResponse.accessToken).then(contentResponse => setContentData(contentResponse));
            });
        });
    }

    return (
        <>
            <Navbar bg="light" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img className="d-inline-block align-top" alt="Home" src="glauxi-home.png"/>
                    </Navbar.Brand>
                    <Navbar.Text className="justify-content-start">
                        <h1>Glauxi</h1>
                    </Navbar.Text>                        
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="user-name" >
                        {name}
                    </Navbar.Text>
                    <Nav.Item>
                        <SignOutButton />
                    </Nav.Item>
                    </Navbar.Collapse>    
                </Container>
            </Navbar>

            {contentData ? 
                <ContentData data={contentData} />
                :
                <div className="text-center">
                    <Button variant="secondary" onClick={RequestContentData}>Load User List</Button>
                </div>
            }

        </>
    );
};

export default App;
