
import { RowData } from "./RowData";

/**
 * Renders information about the user obtained from Microsoft Graph
 */
export const ContentData = (obj) => {
    return (
        <div id="content-div">
            <table className="table">
                <tbody>
                    <tr>
                        <th>Given Name</th><th>Surname</th><th></th>
                    </tr>         

                {obj.data.map(({ id, firstName, lastName, Email }) => (
                    <tr key={id}>
                        <RowData rowData={{ id, firstName, lastName, Email}}  />
                    </tr>
                ))}   
                </tbody>        
            </table>
        </div>
    );
};