import { glauxiApiConfig } from "./authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callGlauxiApi(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers
    };

    return fetch(glauxiApiConfig.glauxiEndpoint, options)
    .then(response =>  response.json())
        .catch(error => console.log(error));
}