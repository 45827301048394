const b2cPolicies = {
    names: {
        signUpSignIn: `${process.env.REACT_APP_B2C_USER_FLOW}`
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${process.env.REACT_APP_B2C_INSTANCE}/${process.env.REACT_APP_B2C_DOMAIN}/${process.env.REACT_APP_B2C_USER_FLOW}`,
        }
    },
    authorityDomain: `${process.env.REACT_APP_B2C_INSTANCE}`
}

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_B2C_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [`${process.env.REACT_APP_B2C_INSTANCE}`],
    redirectUri: `${process.env.REACT_APP_HOSTNAME}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["openid"]
};

// Add scopes here for access token to be used at api endpoints.
// Guid is Azure client id for api
export const tokenRequest = {
    scopes: [`https://${process.env.REACT_APP_B2C_DOMAIN}/${process.env.REACT_APP_B2C_API_CLIENT_ID}/Users.Manage`]
   };

// Add the endpoints here for API services you'd like to use.
export const glauxiApiConfig = {
    glauxiEndpoint: `${process.env.REACT_APP_API_HOSTNAME}${process.env.REACT_APP_API_PATH}/UserManagement/Users`
};